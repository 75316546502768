<template>
    <div id="app">
        <app-header v-if="includeHeader" :data="headerCompData"/>

        <app-notices :data="noticeCompData"/>

        <confirm ref="confirmModal"/>

        <div class="app-admin" :class="adminClasses">
            <div v-if="alerts.length" class="alerts-wrapper">
                <transition-group name="fade">
                    <alert-box v-for="alert in alerts" :alert="alert" @hide="hideAlert" :key="alert.id"/>
                </transition-group>
            </div>

            <activity-spinner v-if="showActivity" ref="activitySpinner"></activity-spinner>
            <loading-spinner v-if="showSpinner" ref="appSpinner"
                :message="spinner.message"
                :size="spinner.size"
                :line-size="spinner.linesize"
                :line-fg-color="spinner.linefgcolor"
                :line-bg-color="spinner.linebgcolor"
                :text-fg-color="spinner.textcolor"
                :speed="spinner.speed">
            </loading-spinner>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: ["pageData"],
        data() {
            return {
                headerCompData: {
                    currentUser: this.pageData.currentUser,
                    appData: this.pageData.appData,
                    memberAreaLinks: this.pageData.memberAreaLinks,
                    searchCompData: this.pageData.searchCompData,
                    searchFilters: this.pageData.searchFilters,
                    notificationCompData: this.pageData.notificationCompData,
                    profileCompData: this.pageData.profileCompData,
                    memberBillingData: this.pageData.memberBillingData,
                },
                noticeCompData: this.pageData.noticeCompData,
                superAdmin: this.pageData.superAdmin,
                includeHeader: this.pageData.includeHeader,
                contentExpanded: true,
                alerts: [],
                showSpinner: false,
                spinner: {},
                showActivity: false,
                desktopView: false,
            }
        },
        beforeMount() {
            /**
             * Run resize
             */
            this.resize()
        },
        mounted() {
            EventBus.$on('confirm:launch', this.confirmModal)
            EventBus.$on('alert:trigger', this.alert)

            EventBus.$on('trigger:activity', () => {
                this.showActivity = true
            })
            EventBus.$on('hide:activity', () => {
                this.showActivity = false
            })
            EventBus.$on('trigger:spinner', this.triggerSpinner)
            EventBus.$on('hide:spinner', this.hideSpinner)

            /**
             * Start resize listener
             */
            window.addEventListener('resize', () => {
                this.doResize()
            })
        },
        computed: {
            adminClasses() {
                return {
                    expanded: this.contentExpanded
                }
            }
        },
        methods: {
            /**
             * Launches confirm modal
             */
            confirmModal(data) {
                if (typeof data.extra === 'undefined') {
                    data.extra = null
                }
                this.$refs.confirmModal.showModal(data)
            },
            /**
             * launches alert
             */
            alert(alert) {
                let updated = false
                alert.popup = true
                alert.type = alert.type || 'info'
                alert.id = alert.id || Date.now()
                this.alerts = _map(this.alerts, a => {
                    if (a.id === alert.id) {
                        updated = true
                        return alert
                    }
                    return a
                })
                if (!updated) {
                    this.alerts = [alert]
                }
            },
            /**
             * Hides the alert
             *
             * @param {string} id
             */
            hideAlert(id) {
                this.alerts = _reject(this.alerts, 'id')
            },
            /**
             * Triggers the spinner
             *
             * @param {object} data
             */
            triggerSpinner(data) {
                if (data === null) {
                    data = {};
                }

                this.spinner.message = data.message ? data.message : Vue.translate('loading') + '..'
                this.spinner.size = data.size ? data.size : 66
                this.spinner.linesize = data.linesize ? data.linesize : 4
                this.spinner.linefgcolor = data.linefgcolor ? data.linefgcolor : '#2196f3'
                this.spinner.linebgcolor = data.linebgcolor ? data.linebgcolor : '#eee'
                this.spinner.textcolor = data.textcolor ? data.textcolor : '#111111'
                this.spinner.speed = data.speed ? data.speed : 0.8
                this.showSpinner = true
            },
            /**
             * Hides the spinner
             *
             */
            hideSpinner() {
                this.showSpinner = false
            },
            /**
             * Checks for header & footer heights & adjusts content positioning
             */
            resize() {
                this.desktopView = window.innerWidth >= 1025
            },
            /**
             * Debounces the resize method
             */
            doResize: _debounce(function() {
                this.resize()
            }, 200),
        }
    }
</script>

<style lang="scss">
    [v-cloak] {
        display: none;
    }
    @media (min-width: 1025px) {
        .cp-aside__brand {
            background-color: #1a1a27;
            -webkit-box-shadow: none;
            box-shadow: none;
            .cp-aside__brand-tools {
                .cp-aside__brand-aside-toggler {
                    svg {
                        g {
                            [fill] {
                                -webkit-transition: fill 0.3s ease;
                                transition: fill 0.3s ease;
                                fill: #494b74;
                            }
                        }
                        &:hover {
                            g {
                                [fill] {
                                    -webkit-transition: fill 0.3s ease;
                                    transition: fill 0.3s ease;
                                }
                            }
                        }
                    }
                    &:hover {
                        svg {
                            g {
                                [fill] {
                                    -webkit-transition: fill 0.3s ease;
                                    transition: fill 0.3s ease;
                                    fill: #5d78ff;
                                }
                            }
                            &:hover {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .cp-aside--minimize {
            .cp-aside-menu {
                .cp-menu__nav {
                    >.cp-menu__item {
                        background: transparent;
                        >.cp-menu__link {
                            >.cp-menu__link-icon {
                                color: #7c87a6;
                            }
                        }
                        &:hover {
                            background: transparent;
                            >.cp-menu__link {
                                >.cp-menu__link-icon {
                                    color: #5d78ff !important;
                                }
                            }
                        }
                    }
                    >.cp-menu__item.cp-menu__item--hover {
                        background: transparent !important;
                        >.cp-menu__link {
                            >.cp-menu__link-icon {
                                color: #5d78ff;
                            }
                        }
                    }
                    >.cp-menu__item.cp-menu__item--open {
                        background: transparent !important;
                        >.cp-menu__link {
                            >.cp-menu__link-icon {
                                color: #5d78ff;
                            }
                        }
                    }
                    >.cp-menu__item.cp-menu__item--here {
                        background: transparent !important;
                        >.cp-menu__link {
                            >.cp-menu__link-icon {
                                color: #5d78ff;
                            }
                        }
                    }
                    >.cp-menu__item.cp-menu__item--active {
                        background: transparent !important;
                        >.cp-menu__link {
                            >.cp-menu__link-icon {
                                color: #5d78ff;
                            }
                        }
                    }
                    .cp-menu__item {
                        &:not([data-cpmenu-submenu-mode=accordion]).cp-menu__item--hover {
                            >.cp-menu__submenu {
                                background-color: #fff;
                                -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
                                box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
                                .cp-menu__subnav {
                                    .cp-menu__item {
                                        >.cp-menu__heading {
                                            .cp-menu__link-text {
                                                color: #696e92;
                                            }
                                            .cp-menu__link-icon {
                                                color: #a8aabb;
                                                svg {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                            fill: #a8aabb;
                                                        }
                                                    }
                                                    &:hover {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                >span {
                                                    background-color: #a8aabb;
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                >span {
                                                    background-color: #a8aabb;
                                                }
                                            }
                                            .cp-menu__ver-arrow {
                                                color: #a8aabb;
                                            }
                                        }
                                        >.cp-menu__link {
                                            .cp-menu__link-text {
                                                color: #696e92;
                                            }
                                            .cp-menu__link-icon {
                                                color: #a8aabb;
                                                svg {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                            fill: #a8aabb;
                                                        }
                                                    }
                                                    &:hover {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                >span {
                                                    background-color: #a8aabb;
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                >span {
                                                    background-color: #a8aabb;
                                                }
                                            }
                                            .cp-menu__ver-arrow {
                                                color: #a8aabb;
                                            }
                                        }
                                        &:not(.cp-menu__item--parent) {
                                            &:not(.cp-menu__item--open) {
                                                &:not(.cp-menu__item--here) {
                                                    &:not(.cp-menu__item--active) {
                                                        &:hover {
                                                            -webkit-transition: background-color 0.3s;
                                                            transition: background-color 0.3s;
                                                            >.cp-menu__heading {
                                                                background-color: #f8f8fb;
                                                                .cp-menu__link-text {
                                                                    color: #5d78ff;
                                                                }
                                                                .cp-menu__link-icon {
                                                                    color: #5d78ff;
                                                                    svg {
                                                                        g {
                                                                            [fill] {
                                                                                -webkit-transition: fill 0.3s ease;
                                                                                transition: fill 0.3s ease;
                                                                                fill: #5d78ff;
                                                                            }
                                                                        }
                                                                        &:hover {
                                                                            g {
                                                                                [fill] {
                                                                                    -webkit-transition: fill 0.3s ease;
                                                                                    transition: fill 0.3s ease;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                                    >span {
                                                                        background-color: #5d78ff;
                                                                    }
                                                                }
                                                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                                    >span {
                                                                        background-color: #5d78ff;
                                                                    }
                                                                }
                                                                .cp-menu__ver-arrow {
                                                                    color: #5d78ff;
                                                                }
                                                            }
                                                            >.cp-menu__link {
                                                                background-color: #f8f8fb;
                                                                .cp-menu__link-text {
                                                                    color: #5d78ff;
                                                                }
                                                                .cp-menu__link-icon {
                                                                    color: #5d78ff;
                                                                    svg {
                                                                        g {
                                                                            [fill] {
                                                                                -webkit-transition: fill 0.3s ease;
                                                                                transition: fill 0.3s ease;
                                                                                fill: #5d78ff;
                                                                            }
                                                                        }
                                                                        &:hover {
                                                                            g {
                                                                                [fill] {
                                                                                    -webkit-transition: fill 0.3s ease;
                                                                                    transition: fill 0.3s ease;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                                    >span {
                                                                        background-color: #5d78ff;
                                                                    }
                                                                }
                                                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                                    >span {
                                                                        background-color: #5d78ff;
                                                                    }
                                                                }
                                                                .cp-menu__ver-arrow {
                                                                    color: #5d78ff;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .cp-menu__item.cp-menu__item--open {
                                        -webkit-transition: background-color 0.3s;
                                        transition: background-color 0.3s;
                                        >.cp-menu__heading {
                                            background-color: #f8f8fb;
                                            .cp-menu__link-text {
                                                color: #5d78ff;
                                            }
                                            .cp-menu__link-icon {
                                                color: #5d78ff;
                                                svg {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                            fill: #5d78ff;
                                                        }
                                                    }
                                                    &:hover {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__ver-arrow {
                                                color: #5d78ff;
                                            }
                                        }
                                        >.cp-menu__link {
                                            background-color: #f8f8fb;
                                            .cp-menu__link-text {
                                                color: #5d78ff;
                                            }
                                            .cp-menu__link-icon {
                                                color: #5d78ff;
                                                svg {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                            fill: #5d78ff;
                                                        }
                                                    }
                                                    &:hover {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__ver-arrow {
                                                color: #5d78ff;
                                            }
                                        }
                                    }
                                    .cp-menu__item.cp-menu__item--here {
                                        -webkit-transition: background-color 0.3s;
                                        transition: background-color 0.3s;
                                        >.cp-menu__heading {
                                            background-color: #f8f8fb;
                                            .cp-menu__link-icon {
                                                svg {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                    &:hover {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                        }
                                        >.cp-menu__link {
                                            background-color: #f8f8fb;
                                            .cp-menu__link-icon {
                                                svg {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                    &:hover {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                        }
                                    }
                                    .cp-menu__item.cp-menu__item--active {
                                        -webkit-transition: background-color 0.3s;
                                        transition: background-color 0.3s;
                                        >.cp-menu__heading {
                                            background-color: #f8f8fb;
                                            .cp-menu__link-text {
                                                color: #5d78ff;
                                            }
                                            .cp-menu__link-icon {
                                                color: #5d78ff;
                                                svg {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                            fill: #5d78ff;
                                                        }
                                                    }
                                                    &:hover {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__ver-arrow {
                                                color: #5d78ff;
                                            }
                                        }
                                        >.cp-menu__link {
                                            background-color: #f8f8fb;
                                            .cp-menu__link-text {
                                                color: #5d78ff;
                                            }
                                            .cp-menu__link-icon {
                                                color: #5d78ff;
                                                svg {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                            fill: #5d78ff;
                                                        }
                                                    }
                                                    &:hover {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__ver-arrow {
                                                color: #5d78ff;
                                            }
                                        }
                                    }
                                    .cp-menu__section {
                                        .cp-menu__section-text {
                                            color: #a4a7bd;
                                        }
                                        .cp-menu__section-icon {
                                            color: #aaacc2;
                                        }
                                    }
                                    .cp-menu__separator {
                                        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .cp-aside-menu.cp-aside-menu--dropdown {
            .cp-menu__nav {
                .cp-menu__item {
                    &:not([data-ktmenu-submenu-mode=accordion]).cp-menu__item--hover {
                        >.cp-menu__submenu {
                            background-color: #fff;
                            -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
                            box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
                            .cp-menu__subnav {
                                .cp-menu__item {
                                    >.cp-menu__heading {
                                        .cp-menu__link-text {
                                            color: #696e92;
                                        }
                                        .cp-menu__link-icon {
                                            color: #a8aabb;
                                            svg {
                                                g {
                                                    [fill] {
                                                        -webkit-transition: fill 0.3s ease;
                                                        transition: fill 0.3s ease;
                                                        fill: #a8aabb;
                                                    }
                                                }
                                                &:hover {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                            >span {
                                                background-color: #a8aabb;
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                            >span {
                                                background-color: #a8aabb;
                                            }
                                        }
                                        .cp-menu__ver-arrow {
                                            color: #a8aabb;
                                        }
                                    }
                                    >.cp-menu__link {
                                        .cp-menu__link-text {
                                            color: #696e92;
                                        }
                                        .cp-menu__link-icon {
                                            color: #a8aabb;
                                            svg {
                                                g {
                                                    [fill] {
                                                        -webkit-transition: fill 0.3s ease;
                                                        transition: fill 0.3s ease;
                                                        fill: #a8aabb;
                                                    }
                                                }
                                                &:hover {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                            >span {
                                                background-color: #a8aabb;
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                            >span {
                                                background-color: #a8aabb;
                                            }
                                        }
                                        .cp-menu__ver-arrow {
                                            color: #a8aabb;
                                        }
                                    }
                                    &:not(.cp-menu__item--parent) {
                                        &:not(.cp-menu__item--open) {
                                            &:not(.cp-menu__item--here) {
                                                &:not(.cp-menu__item--active) {
                                                    &:hover {
                                                        -webkit-transition: background-color 0.3s;
                                                        transition: background-color 0.3s;
                                                        >.cp-menu__heading {
                                                            background-color: #f8f8fb;
                                                            .cp-menu__link-text {
                                                                color: #5d78ff;
                                                            }
                                                            .cp-menu__link-icon {
                                                                color: #5d78ff;
                                                                svg {
                                                                    g {
                                                                        [fill] {
                                                                            -webkit-transition: fill 0.3s ease;
                                                                            transition: fill 0.3s ease;
                                                                            fill: #5d78ff;
                                                                        }
                                                                    }
                                                                    &:hover {
                                                                        g {
                                                                            [fill] {
                                                                                -webkit-transition: fill 0.3s ease;
                                                                                transition: fill 0.3s ease;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                                >span {
                                                                    background-color: #5d78ff;
                                                                }
                                                            }
                                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                                >span {
                                                                    background-color: #5d78ff;
                                                                }
                                                            }
                                                            .cp-menu__ver-arrow {
                                                                color: #5d78ff;
                                                            }
                                                        }
                                                        >.cp-menu__link {
                                                            background-color: #f8f8fb;
                                                            .cp-menu__link-text {
                                                                color: #5d78ff;
                                                            }
                                                            .cp-menu__link-icon {
                                                                color: #5d78ff;
                                                                svg {
                                                                    g {
                                                                        [fill] {
                                                                            -webkit-transition: fill 0.3s ease;
                                                                            transition: fill 0.3s ease;
                                                                            fill: #5d78ff;
                                                                        }
                                                                    }
                                                                    &:hover {
                                                                        g {
                                                                            [fill] {
                                                                                -webkit-transition: fill 0.3s ease;
                                                                                transition: fill 0.3s ease;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                                >span {
                                                                    background-color: #5d78ff;
                                                                }
                                                            }
                                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                                >span {
                                                                    background-color: #5d78ff;
                                                                }
                                                            }
                                                            .cp-menu__ver-arrow {
                                                                color: #5d78ff;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .cp-menu__item.cp-menu__item--open {
                                    -webkit-transition: background-color 0.3s;
                                    transition: background-color 0.3s;
                                    >.cp-menu__heading {
                                        background-color: #f8f8fb;
                                        .cp-menu__link-text {
                                            color: #5d78ff;
                                        }
                                        .cp-menu__link-icon {
                                            color: #5d78ff;
                                            svg {
                                                g {
                                                    [fill] {
                                                        -webkit-transition: fill 0.3s ease;
                                                        transition: fill 0.3s ease;
                                                        fill: #5d78ff;
                                                    }
                                                }
                                                &:hover {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__ver-arrow {
                                            color: #5d78ff;
                                        }
                                    }
                                    >.cp-menu__link {
                                        background-color: #f8f8fb;
                                        .cp-menu__link-text {
                                            color: #5d78ff;
                                        }
                                        .cp-menu__link-icon {
                                            color: #5d78ff;
                                            svg {
                                                g {
                                                    [fill] {
                                                        -webkit-transition: fill 0.3s ease;
                                                        transition: fill 0.3s ease;
                                                        fill: #5d78ff;
                                                    }
                                                }
                                                &:hover {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__ver-arrow {
                                            color: #5d78ff;
                                        }
                                    }
                                }
                                .cp-menu__item.cp-menu__item--here {
                                    -webkit-transition: background-color 0.3s;
                                    transition: background-color 0.3s;
                                    >.cp-menu__heading {
                                        background-color: #f8f8fb;
                                        .cp-menu__link-icon {
                                            svg {
                                                g {
                                                    [fill] {
                                                        -webkit-transition: fill 0.3s ease;
                                                        transition: fill 0.3s ease;
                                                    }
                                                }
                                                &:hover {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                    }
                                    >.cp-menu__link {
                                        background-color: #f8f8fb;
                                        .cp-menu__link-icon {
                                            svg {
                                                g {
                                                    [fill] {
                                                        -webkit-transition: fill 0.3s ease;
                                                        transition: fill 0.3s ease;
                                                    }
                                                }
                                                &:hover {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                    }
                                }
                                .cp-menu__item.cp-menu__item--active {
                                    -webkit-transition: background-color 0.3s;
                                    transition: background-color 0.3s;
                                    >.cp-menu__heading {
                                        background-color: #f8f8fb;
                                        .cp-menu__link-text {
                                            color: #5d78ff;
                                        }
                                        .cp-menu__link-icon {
                                            color: #5d78ff;
                                            svg {
                                                g {
                                                    [fill] {
                                                        -webkit-transition: fill 0.3s ease;
                                                        transition: fill 0.3s ease;
                                                        fill: #5d78ff;
                                                    }
                                                }
                                                &:hover {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__ver-arrow {
                                            color: #5d78ff;
                                        }
                                    }
                                    >.cp-menu__link {
                                        background-color: #f8f8fb;
                                        .cp-menu__link-text {
                                            color: #5d78ff;
                                        }
                                        .cp-menu__link-icon {
                                            color: #5d78ff;
                                            svg {
                                                g {
                                                    [fill] {
                                                        -webkit-transition: fill 0.3s ease;
                                                        transition: fill 0.3s ease;
                                                        fill: #5d78ff;
                                                    }
                                                }
                                                &:hover {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__ver-arrow {
                                            color: #5d78ff;
                                        }
                                    }
                                }
                                .cp-menu__section {
                                    .cp-menu__section-text {
                                        color: #a4a7bd;
                                    }
                                    .cp-menu__section-icon {
                                        color: #aaacc2;
                                    }
                                }
                                .cp-menu__separator {
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1024px) {
        .cp-aside {
            margin-top: 49.5px;
        }
    }
    .cp-aside {
        background-color: #1e1e2d;
        .ps {
            >.ps__rail-x {
                background: transparent;
                &:hover {
                    opacity: 1;
                    background: transparent;
                    >.ps__thumb-x {
                        opacity: 1;
                    }
                }
                &:focus {
                    opacity: 1;
                    background: transparent;
                    >.ps__thumb-x {
                        opacity: 1;
                    }
                }
                >.ps__thumb-x {
                    background: #637099;
                    opacity: 1;
                    &:hover {
                        opacity: 1;
                        background: #637099;
                    }
                    &:focus {
                        opacity: 1;
                        background: #637099;
                    }
                }
            }
            >.ps__rail-y {
                background: transparent;
                &:hover {
                    background: transparent;
                    opacity: 1;
                    >.ps__thumb-y {
                        opacity: 1;
                    }
                }
                &:focus {
                    background: transparent;
                    opacity: 1;
                    >.ps__thumb-y {
                        opacity: 1;
                    }
                }
                >.ps__thumb-y {
                    background: #637099;
                    opacity: 1;
                    &:hover {
                        opacity: 1;
                        background: #637099;
                    }
                    &:focus {
                        opacity: 1;
                        background: #637099;
                    }
                }
            }
        }
        .cp-aside__footer {
            background-color: #1f2231;
            .btn {
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                background-color: transparent;
                i {
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    color: #364b8b;
                }
                &:hover {
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    background-color: #191b27;
                    i {
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                        color: #5d78ff;
                    }
                }
            }
            .show {
                .btn {
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    background-color: #191b27;
                    i {
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                        color: #5d78ff;
                    }
                }
            }
        }
        .cp-aside-menu {
            >.cp-menu__nav {
                .cp-menu__item {
                    >.cp-menu__link {
                        &:not(.cp-menu__link--active) {
                            &:not(.cp-menu__link--here) {
                                &:not(.cp-menu__link--hover) {
                                    [class^="flaticon2-"] {
                                        color: #434d6b;
                                    }
                                    [class*="flaticon2-"] {
                                        color: #434d6b;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cp-aside-menu {
        background-color: #1e1e2d;
        .cp-menu__nav {
            >.cp-menu__item {
                >.cp-menu__heading {
                    .cp-menu__link-text {
                        color: #a2a3b7;
                    }
                    .cp-menu__link-icon {
                        color: #494b74;
                        svg {
                            g {
                                [fill] {
                                    -webkit-transition: fill 0.3s ease;
                                    transition: fill 0.3s ease;
                                    fill: #494b74;
                                }
                            }
                            &:hover {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                    }
                                }
                            }
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                        >span {
                            background-color: #5c5e81;
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--line {
                        >span {
                            background-color: #5c5e81;
                        }
                    }
                    .cp-menu__ver-arrow {
                        color: #5c5e81;
                    }
                }
                >.cp-menu__link {
                    .cp-menu__link-text {
                        color: #a2a3b7;
                    }
                    .cp-menu__link-icon {
                        color: #494b74;
                        svg {
                            g {
                                [fill] {
                                    -webkit-transition: fill 0.3s ease;
                                    transition: fill 0.3s ease;
                                    fill: #494b74;
                                }
                            }
                            &:hover {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                    }
                                }
                            }
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                        >span {
                            background-color: #5c5e81;
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--line {
                        >span {
                            background-color: #5c5e81;
                        }
                    }
                    .cp-menu__ver-arrow {
                        color: #5c5e81;
                    }
                }
                &:not(.cp-menu__item--parent) {
                    &:not(.cp-menu__item--open) {
                        &:not(.cp-menu__item--here) {
                            &:not(.cp-menu__item--active) {
                                &:hover {
                                    -webkit-transition: background-color 0.3s;
                                    transition: background-color 0.3s;
                                    >.cp-menu__heading {
                                        background-color: #1b1b28;
                                        .cp-menu__link-text {
                                            color: #ffffff;
                                        }
                                        .cp-menu__link-icon {
                                            color: #5d78ff;
                                            svg {
                                                g {
                                                    [fill] {
                                                        -webkit-transition: fill 0.3s ease;
                                                        transition: fill 0.3s ease;
                                                        fill: #5d78ff;
                                                    }
                                                }
                                                &:hover {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__ver-arrow {
                                            color: #ffffff;
                                        }
                                    }
                                    >.cp-menu__link {
                                        background-color: #1b1b28;
                                        .cp-menu__link-text {
                                            color: #ffffff;
                                        }
                                        .cp-menu__link-icon {
                                            color: #5d78ff;
                                            svg {
                                                g {
                                                    [fill] {
                                                        -webkit-transition: fill 0.3s ease;
                                                        transition: fill 0.3s ease;
                                                        fill: #5d78ff;
                                                    }
                                                }
                                                &:hover {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                            >span {
                                                background-color: #5d78ff;
                                            }
                                        }
                                        .cp-menu__ver-arrow {
                                            color: #ffffff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                >.cp-menu__arrow {
                    color: #1e1e2d;
                }
                .cp-menu__submenu {
                    .cp-menu__item {
                        >.cp-menu__heading {
                            .cp-menu__link-text {
                                color: #9899ac;
                            }
                            .cp-menu__link-icon {
                                color: #494b74;
                                svg {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                            fill: #494b74;
                                        }
                                    }
                                    &:hover {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                            }
                                        }
                                    }
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                >span {
                                    background-color: #5c5e81;
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                >span {
                                    background-color: #5c5e81;
                                }
                            }
                            .cp-menu__ver-arrow {
                                color: #5c5e81;
                            }
                        }
                        >.cp-menu__link {
                            .cp-menu__link-text {
                                color: #9899ac;
                            }
                            .cp-menu__link-icon {
                                color: #494b74;
                                svg {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                            fill: #494b74;
                                        }
                                    }
                                    &:hover {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                            }
                                        }
                                    }
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                >span {
                                    background-color: #5c5e81;
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                >span {
                                    background-color: #5c5e81;
                                }
                            }
                            .cp-menu__ver-arrow {
                                color: #5c5e81;
                            }
                        }
                        &:not(.cp-menu__item--parent) {
                            &:not(.cp-menu__item--open) {
                                &:not(.cp-menu__item--here) {
                                    &:not(.cp-menu__item--active) {
                                        &:hover {
                                            -webkit-transition: background-color 0.3s;
                                            transition: background-color 0.3s;
                                            >.cp-menu__heading {
                                                background-color: #1b1b28;
                                                .cp-menu__link-text {
                                                    color: #ffffff;
                                                }
                                                .cp-menu__link-icon {
                                                    color: #5d78ff;
                                                    svg {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                                fill: #5d78ff;
                                                            }
                                                        }
                                                        &:hover {
                                                            g {
                                                                [fill] {
                                                                    -webkit-transition: fill 0.3s ease;
                                                                    transition: fill 0.3s ease;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                    >span {
                                                        background-color: #5d78ff;
                                                    }
                                                }
                                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                    >span {
                                                        background-color: #5d78ff;
                                                    }
                                                }
                                                .cp-menu__ver-arrow {
                                                    color: #ffffff;
                                                }
                                            }
                                            >.cp-menu__link {
                                                background-color: #1b1b28;
                                                .cp-menu__link-text {
                                                    color: #ffffff;
                                                }
                                                .cp-menu__link-icon {
                                                    color: #5d78ff;
                                                    svg {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                                fill: #5d78ff;
                                                            }
                                                        }
                                                        &:hover {
                                                            g {
                                                                [fill] {
                                                                    -webkit-transition: fill 0.3s ease;
                                                                    transition: fill 0.3s ease;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                    >span {
                                                        background-color: #5d78ff;
                                                    }
                                                }
                                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                    >span {
                                                        background-color: #5d78ff;
                                                    }
                                                }
                                                .cp-menu__ver-arrow {
                                                    color: #ffffff;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .cp-menu__item.cp-menu__item--open {
                        -webkit-transition: background-color 0.3s;
                        transition: background-color 0.3s;
                        >.cp-menu__heading {
                            background-color: #1b1b28;
                            .cp-menu__link-text {
                                color: #ffffff;
                            }
                            .cp-menu__link-icon {
                                color: #5d78ff;
                                svg {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                            fill: #5d78ff;
                                        }
                                    }
                                    &:hover {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                            }
                                        }
                                    }
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__ver-arrow {
                                color: #ffffff;
                            }
                        }
                        >.cp-menu__link {
                            background-color: #1b1b28;
                            .cp-menu__link-text {
                                color: #ffffff;
                            }
                            .cp-menu__link-icon {
                                color: #5d78ff;
                                svg {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                            fill: #5d78ff;
                                        }
                                    }
                                    &:hover {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                            }
                                        }
                                    }
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__ver-arrow {
                                color: #ffffff;
                            }
                        }
                    }
                    .cp-menu__item.cp-menu__item--here {
                        -webkit-transition: background-color 0.3s;
                        transition: background-color 0.3s;
                        >.cp-menu__heading {
                            background-color: #1b1b28;
                            .cp-menu__link-icon {
                                color: #5d78ff;
                                svg {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                            fill: #5d78ff;
                                        }
                                    }
                                    &:hover {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                            }
                                        }
                                    }
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__ver-arrow {
                                color: #ffffff;
                            }
                        }
                        >.cp-menu__link {
                            background-color: #1b1b28;
                            .cp-menu__link-icon {
                                color: #5d78ff;
                                svg {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                            fill: #5d78ff;
                                        }
                                    }
                                    &:hover {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                            }
                                        }
                                    }
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__ver-arrow {
                                color: #ffffff;
                            }
                        }
                    }
                    .cp-menu__item.cp-menu__item--active {
                        -webkit-transition: background-color 0.3s;
                        transition: background-color 0.3s;
                        >.cp-menu__heading {
                            background-color: #1b1b28;
                            .cp-menu__link-text {
                                color: #ffffff;
                            }
                            .cp-menu__link-icon {
                                color: #5d78ff;
                                svg {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                            fill: #5d78ff;
                                        }
                                    }
                                    &:hover {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                            }
                                        }
                                    }
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__ver-arrow {
                                color: #ffffff;
                            }
                        }
                        >.cp-menu__link {
                            background-color: #1b1b28;
                            .cp-menu__link-text {
                                color: #ffffff;
                            }
                            .cp-menu__link-icon {
                                color: #5d78ff;
                                svg {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                            fill: #5d78ff;
                                        }
                                    }
                                    &:hover {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                            }
                                        }
                                    }
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                >span {
                                    background-color: #5d78ff;
                                }
                            }
                            .cp-menu__ver-arrow {
                                color: #ffffff;
                            }
                        }
                    }
                    .cp-menu__section {
                        .cp-menu__section-text {
                            color: #4c4e6f;
                        }
                        .cp-menu__section-icon {
                            color: #4c4e6f;
                        }
                    }
                    .cp-menu__separator {
                        border-bottom: 1px solid #4c4e6f;
                    }
                }
            }
            >.cp-menu__item.cp-menu__item--open {
                -webkit-transition: background-color 0.3s;
                transition: background-color 0.3s;
                >.cp-menu__heading {
                    background-color: #1b1b28;
                    .cp-menu__link-text {
                        color: #ffffff;
                    }
                    .cp-menu__link-icon {
                        color: #5d78ff;
                        svg {
                            g {
                                [fill] {
                                    -webkit-transition: fill 0.3s ease;
                                    transition: fill 0.3s ease;
                                    fill: #5d78ff;
                                }
                            }
                            &:hover {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                    }
                                }
                            }
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--line {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__ver-arrow {
                        color: #ffffff;
                    }
                }
                >.cp-menu__link {
                    background-color: #1b1b28;
                    .cp-menu__link-text {
                        color: #ffffff;
                    }
                    .cp-menu__link-icon {
                        color: #5d78ff;
                        svg {
                            g {
                                [fill] {
                                    -webkit-transition: fill 0.3s ease;
                                    transition: fill 0.3s ease;
                                    fill: #5d78ff;
                                }
                            }
                            &:hover {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                    }
                                }
                            }
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--line {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__ver-arrow {
                        color: #ffffff;
                    }
                }
            }
            >.cp-menu__item.cp-menu__item--here {
                -webkit-transition: background-color 0.3s;
                transition: background-color 0.3s;
                >.cp-menu__heading {
                    background-color: #1b1b28;
                    .cp-menu__link-text {
                        color: #ffffff;
                    }
                    .cp-menu__link-icon {
                        color: #5d78ff;
                        svg {
                            g {
                                [fill] {
                                    -webkit-transition: fill 0.3s ease;
                                    transition: fill 0.3s ease;
                                    fill: #5d78ff;
                                }
                            }
                            &:hover {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                    }
                                }
                            }
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--line {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__ver-arrow {
                        color: #ffffff;
                    }
                }
                >.cp-menu__link {
                    background-color: #1b1b28;
                    .cp-menu__link-text {
                        color: #ffffff;
                    }
                    .cp-menu__link-icon {
                        color: #5d78ff;
                        svg {
                            g {
                                [fill] {
                                    -webkit-transition: fill 0.3s ease;
                                    transition: fill 0.3s ease;
                                    fill: #5d78ff;
                                }
                            }
                            &:hover {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                    }
                                }
                            }
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--line {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__ver-arrow {
                        color: #ffffff;
                    }
                }
            }
            >.cp-menu__item.cp-menu__item--active {
                -webkit-transition: background-color 0.3s;
                transition: background-color 0.3s;
                >.cp-menu__heading {
                    background-color: #1b1b28;
                    .cp-menu__link-text {
                        color: #ffffff;
                    }
                    .cp-menu__link-icon {
                        color: #5d78ff;
                        svg {
                            g {
                                [fill] {
                                    -webkit-transition: fill 0.3s ease;
                                    transition: fill 0.3s ease;
                                    fill: #5d78ff;
                                }
                            }
                            &:hover {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                    }
                                }
                            }
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--line {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__ver-arrow {
                        color: #ffffff;
                    }
                }
                >.cp-menu__link {
                    background-color: #1b1b28;
                    .cp-menu__link-text {
                        color: #ffffff;
                    }
                    .cp-menu__link-icon {
                        color: #5d78ff;
                        svg {
                            g {
                                [fill] {
                                    -webkit-transition: fill 0.3s ease;
                                    transition: fill 0.3s ease;
                                    fill: #5d78ff;
                                }
                            }
                            &:hover {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                    }
                                }
                            }
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__link-bullet.cp-menu__link-bullet--line {
                        >span {
                            background-color: #5d78ff;
                        }
                    }
                    .cp-menu__ver-arrow {
                        color: #ffffff;
                    }
                }
            }
            >.cp-menu__section {
                .cp-menu__section-text {
                    color: #4c4e6f;
                }
                .cp-menu__section-icon {
                    color: #4c4e6f;
                }
            }
            >.cp-menu__separator {
                border-bottom: 1px solid #4c4e6f;
            }
        }
    }
    .cp-aside-close {
        background-color: rgba(255, 255, 255, 0.2);
        i {
            color: rgba(255, 255, 255, 0.7);
        }
        &:hover {
            background-color: #5d78ff;
            i {
                color: #ffffff;
            }
        }
    }
</style>
