import App from './theme/App.vue'

Vue.translations(PageData.l10n)

class AppTemplate {
    constructor(data) {
        new Vue({
            el: '#app-template',
            components: {},
            data: {},
            render: h => h(App, {props: {pageData: data}})
        })
    }
}

window.AppTemplate = AppTemplate

